import { decryptDataWithAES, encryptDataWithAES } from '../constant/sdk_set';

//  Set Items in local storage
export const set_data_in_local_storage = ({
  key_name,
  value,
  isEncrypt,
}: {
  key_name: string;
  value: any;
  isEncrypt: boolean;
}) => {
  const localStorage_value = isEncrypt
    ? encryptDataWithAES(JSON.stringify(value))
    : JSON.stringify(value);

  //
  localStorage.setItem(key_name, localStorage_value);
};

//  Get Items from local storage
export const get_data_from_local_storage = ({
  key_name,
  isEncrypt,
}: {
  key_name: string;
  isEncrypt?: boolean;
}) => {
  const localStorage_value = localStorage.getItem(key_name)
    ? isEncrypt
      ? decryptDataWithAES(localStorage.getItem(key_name))
        ? JSON.parse(decryptDataWithAES(localStorage.getItem(key_name)))
        : null
      : JSON.parse(localStorage.getItem(key_name))
    : null;

  return localStorage_value;
};

//
export const delete_item_from_local_storage = ({
  key_name,
}: {
  key_name: string;
}) => {
  localStorage.removeItem(key_name);
};
