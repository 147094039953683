import React from 'react';
import { Image } from 'next-images';

const CustomPageError = ({ message, image, messages_box }) => {
  return (
    <div className="w-screen h-screen flex justify-center items-center">
      <div>
        <article className=" max-w-sm  flex  flex-col items-center justify-center gap-5 ">
          <p className=" text-black font-semibold  text-4xl ">404</p>

          {message && (
            <h2 className="text-xl text-center ">
              {message ?? 'This page could not be found.'}
            </h2>
          )}

          {messages_box && messages_box}
        </article>
      </div>
    </div>
  );
};

export default CustomPageError;
