/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';
import { set_data_in_local_storage } from '../../../utils/localStorage';

export const depositByPawaPay = createAsyncThunk(
  'web_payment/depositByPawaPay',
  async ({ ...params }, thunkAPI) => {
    console.log({ params });
    try {
      const response = await axios.post('/api/web_payments/pawa_pay', {
        data: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const webPaymentSlice = createSlice({
  name: 'web_payment',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    pawa_pay: null,
  },
  reducers: {
    clearWebPaymentState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.pawa_pay = null;
      return state;
    },
  },
  extraReducers: {
    [depositByPawaPay.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.pawa_pay = payload;
      }
    },
    [depositByPawaPay.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [depositByPawaPay.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const { clearWebPaymentState } = webPaymentSlice.actions;
export const webPaymentSelector = (state) => state.web_payment;
