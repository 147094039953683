/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

//
export const getKYCDetails = createAsyncThunk(
  'kyc/getKYCDetails',

  async ({ ...prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/kyc/details', {
        params: prams,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//

export const kycFullFOrmSubmission = createAsyncThunk(
  'kyc/kycFullFOrmSubmission',

  async (
    {
      personal_details,
      document,
      bank_details,
      registration_proof,
      articles_of_association,
      shareholder_declaration,
    },
    thunkAPI
  ) => {
    try {
      // personal details
      const personal_details_resp = await axios.post(
        '/api/kyc/personal_details',
        personal_details
      );

      // bank details
      const bank_details_resp = await axios.post(
        '/api/kyc/bank_details',
        bank_details
      );

      // document
      const document_resp = await axios.post('/api/kyc/document', document);

      if (
        registration_proof &&
        articles_of_association &&
        shareholder_declaration
      ) {
        // registration proof
        const registration_proof_resp = await axios.post(
          '/api/kyc/document',
          registration_proof
        );

        // articles of association
        const articles_of_association_resp = await axios.post(
          '/api/kyc/document',
          articles_of_association
        );

        // shareholder declaration
        const shareholder_declaration_resp = await axios.post(
          '/api/kyc/document',
          shareholder_declaration
        );
      }

      const { data } = await document_resp;
      if (
        !document_resp.data.error &&
        !personal_details_resp.data.error &&
        !bank_details_resp.data.error
      ) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//

export const kycBankFormSubmission = createAsyncThunk(
  'kyc/kycBankFormSubmission',

  async ({ bank_details }, thunkAPI) => {
    try {
      // bank details
      const bank_details_resp = await axios.post(
        '/api/kyc/bank_details',
        bank_details
      );

      const { data } = await bank_details_resp;
      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const kycSlice = createSlice({
  name: 'kyc',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isSubmissionLoading: false,
    isSubmissionSuccess: false,
    isError: false,
    kyc_details: null,
    errorMessage: '',
    open_kyc_by_default: false,
  },
  reducers: {
    clearKYCDetails: (state) => {
      state.kyc_details = null;
      return state;
    },
    kycOpenHandler: (state, { payload }) => {
      state.open_kyc_by_default = payload;
      return state;
    },
    clearKYCSubmission: (state) => {
      state.isSubmissionLoading = false;
      state.isSubmissionSuccess = false;
      return state;
    },
    clearKYCError: (state) => {
      state.isError = false;
      state.errorMessage = '';
      return state;
    },
  },
  extraReducers: {
    //getWalletBalance
    [getKYCDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.kyc_details = payload;
      }
    },

    [getKYCDetails.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getKYCDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //amountPayInByMangoPay
    [kycFullFOrmSubmission.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isSubmissionLoading = false;
        state.isError = true;
        state.isSubmissionSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isSubmissionLoading = false;
        state.isSubmissionSuccess = true;
        state.errorMessage = '';
      }
    },

    [kycFullFOrmSubmission.pending]: (state) => {
      state.isSubmissionLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [kycFullFOrmSubmission.rejected]: (state, { payload }) => {
      state.isSubmissionLoading = false;
      state.isError = true;
      state.isSubmissionSuccess = false;
      state.errorMessage = payload?.message;
    },
    //amountPayInByMangoPay
    [kycBankFormSubmission.fulfilled]: (state, { payload }) => {
      if (payload?.code) {
        state.isSubmissionLoading = false;
        state.isError = true;
        state.isSubmissionSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isSubmissionLoading = false;
        state.isSubmissionSuccess = true;
        state.errorMessage = '';
      }
    },

    [kycBankFormSubmission.pending]: (state) => {
      state.isSubmissionLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [kycBankFormSubmission.rejected]: (state, { payload }) => {
      state.isSubmissionLoading = false;
      state.isError = true;
      state.isSubmissionSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const {
  clearBalance,
  clearPayIN,
  clearKYCSubmission,
  clearKYCError,
  kycOpenHandler,
} = kycSlice.actions;
export const KYCSelector = (state) => state.kyc;
