/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

export const getDigitalContent = createAsyncThunk(
  'digital_content/getDigitalContent',
  async ({ listing_id, bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/digital_contents', {
        params: { bodyParam, listing_id },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getVariantDigitalContent = createAsyncThunk(
  'digital_content/getVariantDigitalContent',
  async ({ listing_id, variant_id }, thunkAPI) => {
    try {
      const response = await axios.get('/api/digital_contents', {
        params: { listing_id, variant_id },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const digitalContentSlice = createSlice({
  name: 'digital_content',
  initialState: {
    isFetching: false,
    isError: false,
    errorMessage: '',
    isSuccess: '',
    main_listing_digital_content: {},
    variant_digital_content: {},
  },
  reducers: {
    clearDigitalContentState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.digital_content = {};
      return state;
    },
  },
  extraReducers: {
    [getDigitalContent.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getDigitalContent.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = '';
      state.main_listing_digital_content =
        payload?.digital_contents?.length > 0
          ? payload?.digital_contents[0]
          : {};
    },
    [getDigitalContent.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
      state.main_listing_digital_content = {};
    },

    [getVariantDigitalContent.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getVariantDigitalContent.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = '';
      state.variant_digital_content =
        payload?.digital_contents?.length > 0
          ? payload?.digital_contents[0]
          : {};
    },

    [getVariantDigitalContent.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
      state.variant_digital_content = {};
    },
  },
});

export const { clearDigitalContentState } = digitalContentSlice.actions;
export const digitalContentSelector = (state) => state.digital_content;
