/* eslint-disable no-param-reassign */
import { createSlice as cardSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

//
export const getSavedCards = createAsyncThunk(
  'card/getSavedCards',

  async ({ ...params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/card', {
        params: params,
      });
      const { data } = await response;

      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const starCardRegistration = createAsyncThunk(
  'card/starCardRegistration',

  async ({ post_data }, thunkAPI) => {
    try {
      const response = await axios.post('/api/card/registration', post_data);
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const cardSubmission = createAsyncThunk(
  'card/cardSubmission',

  async ({ ...post_data }, thunkAPI) => {
    try {
      // mangopay_registration
      const response = await axios.post(
        '/api/card/mangopay_registration',
        post_data
      );

      if (!response?.data) {
        throw error('Something went wrong');
      }

      // registration_confirmation
      const response2 = await axios.post(
        '/api/card/registration_confirmation',
        {
          card_registration_id: post_data?.id,
          registration_data: response?.data,
          currency: post_data?.currency ?? '',
        }
      );

      const { data } = await response2;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const removeCardFromSavedList = createAsyncThunk(
  'card/removeCardFromSavedList',

  async ({ card_id }, thunkAPI) => {
    try {
      console.log(card_id);
      // mangopay_registration
      const response = await axios.delete('/api/card/delete', {
        data: { card_id: card_id },
      });

      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const CardSlice = cardSlice({
  name: 'card',
  initialState: {
    isFetching: false,
    isDeleteLoading: false,
    cardSubmissionLoading: false,
    cardSubmissionSuccess: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    cards: null,
    card_reg_data: null,
    mangopay_card_reg_data: null,
    selected_card_id: null,
  },
  reducers: {
    clearCards: (state) => {
      state.cards = null;
      return state;
    },
    clearCardRegData: (state) => {
      state.card_reg_data = null;
      state.cardSubmissionSuccess = false;
      return state;
    },
    clearCardError: (state) => {
      state.isError = false;
      state.errorMessage = '';
      return state;
    },
  },
  extraReducers: {
    //getWalletBalance
    [getSavedCards.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.cards = payload?.saved_cards;
      }
    },

    [getSavedCards.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getSavedCards.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //removeCardFromSavedList
    [removeCardFromSavedList.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isDeleteLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
        state.selected_card_id = null;
      } else {
        state.isDeleteLoading = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.selected_card_id = null;
      }
    },

    [removeCardFromSavedList.pending]: (state, { payload, meta }) => {
      state.isDeleteLoading = true;
      state.isError = false;
      state.errorMessage = '';
      state.selected_card_id = meta?.arg?.card_id;
    },
    [removeCardFromSavedList.rejected]: (state, { payload }) => {
      state.isDeleteLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.selected_card_id = null;
    },
    //starCardRegistration
    [starCardRegistration.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.card_reg_data = payload;
      }
    },

    [starCardRegistration.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [starCardRegistration.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //cardSubmission
    [cardSubmission.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.cardSubmissionLoading = false;
        state.isError = true;
        state.cardSubmissionSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.cardSubmissionLoading = false;
        state.cardSubmissionSuccess = true;
        state.errorMessage = '';
      }
    },
    [cardSubmission.pending]: (state) => {
      state.cardSubmissionLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [cardSubmission.rejected]: (state, { payload }) => {
      state.cardSubmissionLoading = false;
      state.isError = true;
      state.cardSubmissionSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const { clearCards, clearCardRegData, clearCardError } =
  CardSlice.actions;
export const CardSelector = (state) => state.card;
