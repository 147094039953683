/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const getCommissions = createAsyncThunk(
  'collections/getCommissions',
  async ({ bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/commissions', {
        params: { ...bodyParam },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getDemandCommissions = createAsyncThunk(
  'collections/getDemandCommissions',
  async ({ bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/commissions', {
        params: { ...bodyParam },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const commissionsSlice = createSlice({
  name: 'commissions',
  initialState: {
    isFetching: false,
    isError: false,
    errorMessage: '',
    isSuccess: '',
    commissions: [],
    demand_commissions: [],
  },
  reducers: {
    clearCommissionsState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.commissions = [];
      return state;
    },
  },
  extraReducers: {
    //
    [getCommissions.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getCommissions.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = '';
      state.commissions = payload?.commissions;
    },
    [getCommissions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      state.commissions = [];
    },
    //
    [getDemandCommissions.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getDemandCommissions.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = '';
      state.demand_commissions = payload?.commissions;
    },
    [getDemandCommissions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      state.demand_commissions = [];
    },
  },
});

export const { clearCollectionsState } = commissionsSlice.actions;
export const commissionsSelector = (state) => state.commissions;
