/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

export const getLayerDetails = createAsyncThunk(
  'layer/getLayerDetails',
  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/search', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getBlogDetails = createAsyncThunk(
  'layer/getBlogDetails',
  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/c/article_details', {
        params: prams,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const layerSlice = createSlice({
  name: 'layer',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    layers: null,
    page: '',
    total_records: '',
    layer_details: null,
    blog_details: null,
    page_details: null,
  },
  reducers: {
    clearLayerDetailsState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.layer_details = null;
      return state;
    },
    setLayerDetails: (state, { payload }) => {
      state.layer_details = payload?.layer_details;
      return state;
    },
    setBlogDetails: (state, { payload }) => {
      state.blog_details = payload?.blog_details;
      return state;
    },
    setPageDetails: (state, { payload }) => {
      state.page_details = payload?.page_details;
      return state;
    },
  },
  extraReducers: {
    //
    [getLayerDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.layer_details = payload?.layer;
      }
    },
    [getLayerDetails.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getLayerDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //
    [getBlogDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.blog_details = payload?.layer;
      }
    },
    [getBlogDetails.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getBlogDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const {
  clearLayerDetailsState,
  setLayerDetails,
  setBlogDetails,
  setPageDetails,
} = layerSlice.actions;
export const layerSelector = (state) => state.layer;
