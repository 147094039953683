/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

export const getDynamicPageSections = createAsyncThunk(
  'home/getDynamicPageSections',

  async ({ sections_data }, thunkAPI) => {
    let promises = sections_data
      .filter((item) => !item.isStatic || item.block === 'promo_banner')
      .map((item) => {
        switch (item.block) {
          case 'category':
            return {
              fetch_call: axios.get('/api/categories', {
                params: { ...item.fetch_by, parent: 0 },
              }),
              ...item,
            };

          case 'listing_collection':
            return {
              fetch_call: axios.get('/api/collections', {
                params: item.fetch_by,
              }),
              ...item,
            };
          case 'account_collection':
            return {
              fetch_call: axios.get('/api/collections', {
                params: item.fetch_by,
              }),
              ...item,
            };
          case 'listing_cities':
            return {
              fetch_call: axios.get('/api/l/locations', {
                params: { ...item.fetch_by, page: 1, type: 'city' },
              }),
              ...item,
            };
          case 'layer_pages':
            return {
              fetch_call: axios.get('/api/c/article', {
                params: { ...item.fetch_by, page: 1 },
              }),
              ...item,
            };
          case 'layer_blogs':
            return {
              fetch_call: axios.get('/api/c/article', {
                params: { ...item.fetch_by, page: 1 },
              }),
              ...item,
            };
          case 'promo_banner':
            return {
              fetch_call: axios.get('/api/banners', {
                params: { ...item.fetch_by, page: 1 },
              }),
              ...item,
            };
          case 'all_listings':
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
          case 'all_listings_with_map_view':
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
          case 'listings_map_view':
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
          case 'all_accounts':
            return {
              fetch_call: axios.get('/api/a', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
          default:
            return { fetch_call: axios.get('/api/home'), ...item };
        }
      });

    try {
      let all_response = await Promise.all(
        promises.map((item) => item.fetch_call)
      );
      return all_response.map((response, index) => {
        delete promises[index].fetch_call;

        switch (promises[index].block) {
          case 'all_listings':
            return {
              ...promises[index],
              data: response.data,
            };
          case 'all_listings_with_map_view':
            return {
              ...promises[index],
              data: response.data,
            };
          case 'listings_map_view':
            return {
              ...promises[index],
              data: response.data,
            };
          case 'all_accounts':
            return {
              ...promises[index],
              data: response.data,
            };
          default:
            return {
              ...promises[index],
              data:
                response.data[promises[index].block_data_key] ?? response.data,
            };
        }
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors.errors);
    }
  }
);

//Refecth section data
export const getDynamicPageSection = createAsyncThunk(
  'home/getDynamicPageSection',

  async ({ sections_data }, thunkAPI) => {
    let promises = sections_data
      .filter((item) => !item.isStatic || item.block === 'promo_banner')
      .map((item) => {
        switch (item.block) {
          case 'category':
            return {
              fetch_call: axios.get('/api/categories', {
                params: { ...item.fetch_by, parent: 0 },
              }),
              ...item,
            };

          case 'listing_collection':
            return {
              fetch_call: axios.get('/api/collections', {
                params: item.fetch_by,
              }),
              ...item,
            };

          case 'account_collection':
            return {
              fetch_call: axios.get('/api/collections', {
                params: item.fetch_by,
              }),
              ...item,
            };
          case 'listing_cities':
            return {
              fetch_call: axios.get('/api/l/locations', {
                params: { ...item.fetch_by, page: 1, type: 'city' },
              }),
              ...item,
            };
          case 'layers':
            return {
              fetch_call: axios.get('/api/c/article', {
                params: { ...item.fetch_by, page: 1 },
              }),
              ...item,
            };
          case 'layer_pages':
            return {
              fetch_call: axios.get('/api/c/article', {
                params: { ...item.fetch_by, page: 1 },
              }),
              ...item,
            };
          case 'layer_blogs':
            return {
              fetch_call: axios.get('/api/c/article', {
                params: { ...item.fetch_by, page: 1 },
              }),
              ...item,
            };
          case 'all_listings':
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
          case 'all_listings_with_map_view':
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
          case 'listings_map_view':
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
          case 'all_accounts':
            return {
              fetch_call: axios.get('/api/a', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
          case 'promo_banner':
            return {
              fetch_call: axios.get('/api/banners', {
                params: { ...item.fetch_by, page: 1 },
              }),
              ...item,
            };
          default:
            return { fetch_call: axios.get('/api/home'), ...item };
        }
      });

    try {
      let all_response = await Promise.all(
        promises.map((item) => item.fetch_call)
      );
      return all_response.map((response, index) => {
        // sending response
        switch (promises[index].block) {
          case 'all_listings':
            delete promises[index].fetch_call;
            return {
              ...promises[index],
              data: {
                listings:
                  promises[index].extra_data?.pagination_type === 'scroll' &&
                  promises[index].extra_data?.fetching_type !==
                    'fetching_by_effect'
                    ? [
                        ...promises[index].data.listings,
                        ...response.data?.listings,
                      ]
                    : response.data?.listings,
                page: response.data?.page,
                total_records: response.data?.total_records,
              },
            };
          case 'all_listings_with_map_view':
            delete promises[index].fetch_call;
            return {
              ...promises[index],
              data: {
                listings:
                  promises[index].extra_data?.pagination_type === 'scroll' &&
                  promises[index].extra_data?.fetching_type !==
                    'fetching_by_effect'
                    ? [
                        ...promises[index].data.listings,
                        ...response.data?.listings,
                      ]
                    : response.data?.listings,
                page: response.data?.page,
                total_records: response.data?.total_records,
              },
            };
          case 'listings_map_view':
            delete promises[index].fetch_call;
            return {
              ...promises[index],
              data: {
                listings:
                  promises[index].extra_data?.pagination_type === 'scroll' &&
                  promises[index].extra_data?.fetching_type !==
                    'fetching_by_effect'
                    ? [
                        ...promises[index].data.listings,
                        ...response.data?.listings,
                      ]
                    : response.data?.listings,
                page: response.data?.page,
                total_records: response.data?.total_records,
              },
            };
          case 'all_accounts':
            delete promises[index].fetch_call;
            return {
              ...promises[index],
              data: {
                accounts:
                  promises[index].extra_data?.pagination_type === 'scroll' &&
                  promises[index].extra_data?.fetching_type !==
                    'fetching_by_effect'
                    ? [
                        ...promises[index].data.accounts,
                        ...response.data?.accounts,
                      ]
                    : response.data?.accounts,
                page: response.data?.page,
                total_records: response.data?.total_records,
              },
            };
          default:
            delete promises[index].fetch_call;
            return {
              ...promises[index],
              data:
                response.data[promises[index].block_data_key] ?? response.data,
            };
        }
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors.errors);
    }
  }
);

export const dynamicPageSlice = createSlice({
  name: 'dynamicPage',
  initialState: {
    isFetching: false,
    isSingleSectionFetching: '',
    isSuccess: false,
    isError: false,
    errors: null,
    errorMessage: '',
    dynamic_page_sections: null,
    listing_view_type: 'gallery_view',
  },
  reducers: {
    clearErrorState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errors = null;
      return state;
    },
    dynamicPageSectionOptimisticUpdate: (state, { payload }) => {
      // Always remember this optimistic update not like RTK query , this is some advance functionalities created for best exprience , please look at the dispatch calling
      state.dynamic_page_sections = payload;
      return state;
    },
    setError: (state, { payload }) => {
      state.isError = true;
      state.errors = payload;
      return state;
    },
    updateListingViewType: (state, { payload }) => {
      state.listing_view_type = payload;
      return state;
    },
  },
  extraReducers: {
    [getDynamicPageSections.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
      errors: null;
    },
    [getDynamicPageSections.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.dynamic_page_sections = payload;
    },

    [getDynamicPageSections.rejected]: (state, { error, payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errors = payload;
    },
    ///
    [getDynamicPageSection.pending]: (state, { meta }) => {
      state.isFetching = true;
      state.isSingleSectionFetching = meta?.arg?.sections_data[0]?.block;
      state.isError = false;
      state.errorMessage = '';
      errors: null;
    },
    [getDynamicPageSection.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSingleSectionFetching = '';

      state.isSuccess = true;
      state.isError = false;
      state.dynamic_page_sections = [
        ...state.dynamic_page_sections?.filter(
          (section) =>
            !payload.some(
              (nupdated_section) =>
                nupdated_section.order_number === section.order_number
            )
        ),
        ...payload,
      ];
    },

    [getDynamicPageSection.rejected]: (state, { error, payload }) => {
      state.isSingleSectionFetching = '';

      state.isFetching = false;
      state.isError = true;
      state.errors = payload;
    },
  },
});

export const {
  dynamicPageSectionOptimisticUpdate,
  clearErrorState,
  setError,
  updateListingViewType,
} = dynamicPageSlice.actions;

export const dynamicPageSelector = (state) => state.dynamicPage;
