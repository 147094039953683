import { useRouter } from 'next/router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { logout, signInByAuthKey } from '../../store/feature/authSlice';

const SubApp = () => {
  const router = useRouter();
  const dispatch = useDispatch();

  const auth_key = router?.query?.token;
  const refresh_key = router?.query?.refresh_token;

  if (auth_key && refresh_key) {
    dispatch(logout());
    dispatch(signInByAuthKey({ auth_key, refresh_key })).then((res) => {
      if (!res?.payload?.code) {
        router.push(router.asPath?.split('?')[0]);
      } else {
        //   ! need to handle the error message

        //
        router.push(router.asPath?.split('?')[0]);
      }
    });
  }

  return <> </>;
};

export default SubApp;
