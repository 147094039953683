import React from 'react';
import { TYPE_CONSTANT } from '../constant/Web_constant';

const Integrations = ({ all_integrations }) => {
  return (
    <>
      {/* Header Script */}
      {/* {all_integrations?.header_script && (
        <div
          dangerouslySetInnerHTML={{
            __html: `${all_integrations?.header_script}`,
          }}
        />
      )} */}

      {/* Search console */}
      {all_integrations?.google_search_console &&
        Object.keys(all_integrations?.google_search_console)?.length > 0 && (
          <meta
            name="google-site-verification"
            content={all_integrations?.google_search_console?.content}
          />
        )}

      {/* Merchant content console */}
      {all_integrations?.google_merchant_center &&
        Object.keys(all_integrations?.google_merchant_center)?.length > 0 && (
          <meta
            name="google-site-verification"
            content={all_integrations?.google_merchant_center?.content}
          />
        )}

      {/* Full story */}
      {all_integrations?.fullstory && (
        <div
          dangerouslySetInnerHTML={{
            __html: `${all_integrations?.fullstory?.script}`,
          }}
        />
      )}

      {/* Mailchimp */}
      {all_integrations?.mailchimp && all_integrations?.mailchimp?.script && (
        <div
          dangerouslySetInnerHTML={{
            __html: `${all_integrations?.mailchimp?.script}`,
          }}
        />
      )}

      {/* hubspot */}
      {all_integrations?.hubspot && (
        <script
          type="text/javascript"
          id="hs-script-loader"
          async
          defer
          src={`//js.hs-scripts.com/${all_integrations?.hubspot?.tracking_code}.js`}
        ></script>
      )}

      {/* BUY ME A COFEE */}

      {all_integrations?.buymeacoffee && (
        <div
          dangerouslySetInnerHTML={{
            __html: `${all_integrations?.buymeacoffee?.script_code}`,
          }}
        />
      )}

      {/* Google ads pixel */}
      {all_integrations?.google_add_pixel && (
        <div
          dangerouslySetInnerHTML={{
            __html: `${all_integrations?.google_add_pixel?.script}`,
          }}
        />
      )}

      {/* Landbot  */}
      {all_integrations?.landbot && (
        <script
          dangerouslySetInnerHTML={{
            __html:
              all_integrations?.landbot?.type == 'popup'
                ? `window.addEventListener('mouseover', initLandbot, { once: true });
window.addEventListener('touchstart', initLandbot, { once: true });
var myLandbot;
function initLandbot() {
  if (!myLandbot) {
    var s = document.createElement('script');s.type = 'text/javascript';s.async = true;
    s.addEventListener('load', function() {
      myLandbot = new Landbot.Popup({
        configUrl:'${all_integrations?.landbot?.config_url}',
      });
    });
    s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
}`
                : `window.addEventListener('mouseover', initLandbot, { once: true });
window.addEventListener('touchstart', initLandbot, { once: true });
var myLandbot;
function initLandbot() {
  if (!myLandbot) {
    var s = document.createElement('script');s.type = 'text/javascript';s.async = true;
    s.addEventListener('load', function() {
      var myLandbot = new Landbot.Livechat({
        configUrl:'${all_integrations?.landbot?.config_url}',
      });
    });
    s.src = 'https://cdn.landbot.io/landbot-3/landbot-3.0.0.js';
    var x = document.getElementsByTagName('script')[0];
    x.parentNode.insertBefore(s, x);
  }
}`,
          }}
        />
      )}

      {/* hotjar */}
      {all_integrations?.hotjar && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${all_integrations?.hotjar?.hotjar_siteid},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
       })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          }}
        />
      )}

      {/* Freshworks marketting */}
      {all_integrations?.freshworks_marketing && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function() {
 var script = document.createElement('script');
 script.type = 'text/javascript';
 script.src = 'https://in.fw-cdn.com/${all_integrations?.freshworks_marketing?.freshworks_pixel_id}.js';  
 script.setAttribute('chat', 'true');
 document.getElementsByTagName('head')[0].appendChild(script);
 })();`,
          }}
        />
      )}

      {/*
       mailchimp*/}
      {all_integrations?.mailchimp &&
        all_integrations?.mailchimp?.mailchimp_url && (
          <script
            id="mcjs"
            dangerouslySetInnerHTML={{
              __html: `!function(c,h,i,m,p)
        {
          ((m = c.createElement(h)),
          (p = c.getElementsByTagName(h)[0]),
          (m.async = 1),
          (m.src = i),
          p.parentNode.insertBefore(m, p))
        }
        (document,"script",${all_integrations?.mailchimp?.mailchimp_url});`,
            }}
          />
        )}

      {/* <!-- BEGIN PRIVY ASYNCHRONOUS WIDGET CODE --> */}

      {all_integrations?.privy &&
        Object.keys(all_integrations?.privy)?.length > 0 && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `  var _d_site = _d_site ||"${all_integrations.privy?.account_identifier}";
   (function(p, r, i, v, y) {
     p[i] = p[i] || function() { (p[i].q = p[i].q || []).push(arguments) };
     v = r.createElement('script'); v.async = 1; v.src = 'https://widget.privy.com/assets/widget.js';
     y = r.getElementsByTagName('script')[0]; y.parentNode.insertBefore(v, y);
   })(window, document, 'Privy');`,
            }}
          />
        )}

      {/* Global Site Tag (gtag.js) - Google Analytics */}
      {all_integrations?.googleanalytics && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${all_integrations?.googleanalytics?.ga_tracking_id}`}
        />
      )}

      {all_integrations?.googleanalytics && (
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${all_integrations?.googleanalytics?.ga_tracking_id}');
          `,
          }}
        />
      )}

      {/* heap */}
      {all_integrations?.heap && (
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
            window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};   
heap.load(${all_integrations?.heap.app_id});`,
          }}
        />
      )}

      {/* Tidio */}
      {all_integrations?.tidio && (
        <script
          src={`//code.tidio.co/${all_integrations?.tidio?.public_key}.js`}
          async
        ></script>
      )}

      {all_integrations?.Usetiful && (
        <script
          dangerouslySetInnerHTML={{
            __html: `(function (w, d, s) {
    var a = d.getElementsByTagName('head')[0];
    var r = d.createElement('script');
    r.async = 1;
    r.src = s;
    r.setAttribute('id', 'usetifulScript');
    r.dataset.token = '${all_integrations?.Usetiful?.dataset_token}';
                        a.appendChild(r);
  })(window, document, "https://www.usetiful.com/dist/usetiful.js");
             `,
          }}
        />
      )}
    </>
  );
};

export default Integrations;
