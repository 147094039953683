import { metadata, structuredData } from '../constant/Metadata';

export const initial_metadata_set = () => {
  metadata.title = '';
  metadata.meta_description = '';
  metadata.url = '';
  metadata.image = '';
  metadata.favicon = '';
  metadata.primary_font_name = '';
  metadata.title_font_name = '';
  metadata.body_font_name = '';
  structuredData.json_data = {};
};
