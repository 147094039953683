/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';
import { Listing_text } from '../../constant/Translation/Listing';

// listingDetails
export const listingDetails = createAsyncThunk(
  'listing/listingDetails',
  async ({ slug, id, authKey }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/l/${slug}`, { params: { id } });
      const { data } = response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// singleListingDetails
export const singleListingDetails = createAsyncThunk(
  'listing/singleListingDetails',
  async ({ slug, id }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/l/${slug}`, { params: { id } });
      const { data } = response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// listingLike
export const listingLike = createAsyncThunk(
  'listing/listingLike',
  async ({ id, isLiked, authKey }, thunkAPI) => {
    try {
      const response = await axios.post('/api/l/like', {
        id: id,
        isLiked: isLiked,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// listingLike
export const listingUnLike = createAsyncThunk(
  'listing/listingUnLike',
  async ({ id, isUnLiked }, thunkAPI) => {
    try {
      const response = await axios.post('/api/l/unlike', {
        id: id,
        isUnLiked: isUnLiked,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// listingsBulkLike
export const listingsBulkLike = createAsyncThunk(
  'listing/listingsBulkLike',
  async ({ ids }, thunkAPI) => {
    try {
      const response = await axios.post('/api/l/bulk_like', {
        data: { listing_id: ids },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// getAllListings
export const getAllListings = createAsyncThunk(
  'listing/getAllListings',
  async ({ prams, authKey }, thunkAPI) => {
    try {
      const response = await axios.get('/api/l', { params: prams });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// getListingReviews
export const getListingReviews = createAsyncThunk(
  'listing/getListingReviews',
  async ({ authKey, params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/review/get_reviews', {
        params: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
// getListingVariants
export const getListingVariants = createAsyncThunk(
  'listing/getListingVariants',
  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/variant', {
        params: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// getListingQNA
export const getListingQNA = createAsyncThunk(
  'listing/getListingQNA',
  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/comments', {
        params: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//addListingQNA
export const addListingQNA = createAsyncThunk(
  'listing/addListingQNA',
  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.post('/api/comments/post', {
        data: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// getListingComments
export const getListingComments = createAsyncThunk(
  'listing/getListingComments',
  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/comments', {
        params: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//addListingQNA
export const postListingComment = createAsyncThunk(
  'listing/postListingComment',
  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.post('/api/comments/post', {
        data: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//addListingQNA
export const likeUnlikeComment = createAsyncThunk(
  'listing/likeUnlikeComment',
  async ({ ...params }, thunkAPI) => {
    try {
      console.log({ params });
      const response = await axios.post('/api/comments/like_unlike', {
        ...params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

// getListingUpdates
export const getListingUpdates = createAsyncThunk(
  'listing/getListingUpdates',
  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/comments', {
        params: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//addListingUpdate
export const addListingUpdate = createAsyncThunk(
  'listing/addListingQNA',
  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.post('/api/comments/post', {
        data: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//reportListing
export const reportListing = createAsyncThunk(
  'listing/reportListing',
  async ({ listing_id, params }, thunkAPI) => {
    try {
      const response = await axios.post('/api/report/listing', {
        id: listing_id,
        data: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const getListingDonnerList = createAsyncThunk(
  'listing/getListingDonnerList',
  async ({ ...params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/donation/donner_list', {
        params: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const listingSlice = createSlice({
  name: 'listing',
  initialState: {
    isFetching: false,
    isCommentsFetching: false,
    isCommentsLoading: false,
    isCommentLikeUnlikeLoading: false,
    isSuccess: false,
    isShowSuccess: false,
    success_message: '',
    isError: false,
    errorMessage: '',
    listing_details: null,
    single_listing_details: null,
    rating_data: {},
    listings: null,
    page: '',
    total_records: '',
    listings_donner_list: null,
    donner_list_page: '',
    donner_list_total_records: '',
    reviews: null,
    my_review: null,
    review_page: '',
    review_total_records: '',
    qna: null,
    qna_page: '',
    qna_total_records: '',
    updates: null,
    listings_variants_list: null,
    updates_page: '',
    updates_total_records: '',
    comments: null,
    comments_page: '',
    comments_total_record: '',
  },
  reducers: {
    clearListingState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isShowSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
    setListingDetailsData: (state, { payload }) => {
      state.listing_details = payload;
      state.rating_data = payload?.rating_data;
    },
    clearListingDetails: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.listing_details = null;
      state.rating_data = {};
      state.qna = null;
      return state;
    },
    clearListings: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.listings = null;
      state.page = '';
      state.total_records = '';
      state.qna = null;
      return state;
    },
    clearListingsVariants: (state) => {
      state.listings_variants_list = null;

      return state;
    },
  },
  extraReducers: {
    //
    [listingDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
        state.listing_details = undefined;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.listing_details = payload?.listing;
        state.rating_data = payload?.listing?.rating_data;
      }
    },
    [listingDetails.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [listingDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.listing_details = undefined;
    },

    //
    [singleListingDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
        state.listing_details = undefined;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.single_listing_details = payload?.listing;
        state.rating_data = payload?.listing?.rating_data;
      }
    },
    [singleListingDetails.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [singleListingDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.single_listing_details = null;
    },

    //
    [getListingVariants.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
        state.listings_variants_list = null;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.listings_variants_list = payload?.variant_types;
      }
    },
    [getListingVariants.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getListingVariants.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.listings_variants_list = null;
    },

    //
    [listingLike.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
      }
    },
    [listingLike.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [listingLike.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //
    [listingUnLike.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
      }
    },
    [listingUnLike.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [listingUnLike.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //
    [listingsBulkLike.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
      }
    },
    [listingsBulkLike.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [listingsBulkLike.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //
    [getAllListings.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.listings = payload?.listings;
        state.page = payload?.page;
        state.total_records = payload?.total_records;
      }
    },
    [getAllListings.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getAllListings.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [getListingReviews.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.reviews = payload?.reviews;
        state.my_review = payload?.my_review;
        state.review_page = payload?.page;
        state.review_total_records = payload?.total_records;
      }
    },
    [getListingReviews.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getListingReviews.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //
    [getListingQNA.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.qna = payload?.comments;
        // state.qna_page = payload?.page;
        // state.qna_total_records = payload?.total_records;
      }
    },
    [getListingQNA.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getListingQNA.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //
    [addListingQNA.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
      }
    },
    [addListingQNA.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [addListingQNA.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //
    [getListingComments.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.comments = payload?.comments;
        // state.qna_page = payload?.page;
        // state.qna_total_records = payload?.total_records;
      }
    },
    [getListingComments.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getListingComments.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //
    [postListingComment.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isCommentsLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isCommentsLoading = false;
        state.isSuccess = true;
      }
    },
    [postListingComment.pending]: (state) => {
      state.isSuccess = false;
      state.isCommentsLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [postListingComment.rejected]: (state, { payload }) => {
      state.isCommentsLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //
    [likeUnlikeComment.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isCommentLikeUnlikeLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isCommentLikeUnlikeLoading = false;
        state.isSuccess = true;
      }
    },
    [likeUnlikeComment.pending]: (state) => {
      state.isSuccess = false;
      state.isCommentLikeUnlikeLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [likeUnlikeComment.rejected]: (state, { payload }) => {
      state.isCommentLikeUnlikeLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //
    [getListingUpdates.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.updates = payload?.comments;
        // state.qna_page = payload?.page;
        // state.qna_total_records = payload?.total_records;
      }
    },
    [getListingUpdates.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getListingUpdates.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    [addListingUpdate.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
      }
    },
    [addListingUpdate.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [addListingUpdate.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //
    [reportListing.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.success_message = Listing_text.product?.report_success;
        state.isShowSuccess = true;
      }
    },
    [reportListing.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [reportListing.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //
    [getListingDonnerList.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.listings_donner_list = payload?.donations;
        state.donner_list_page = payload?.page;
        state.donner_list_total_records = payload?.total_records;
      }
    },
    [getListingDonnerList.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getListingDonnerList.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const {
  clearListingState,
  clearListingDetails,
  clearListings,
  setListingDetailsData,
  clearListingsVariants,
} = listingSlice.actions;
export const listingSelector = (state) => state.listing;
