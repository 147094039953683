import { TYPE_CONSTANT } from './Web_constant';

export const push_data_layer = ({ event, data }) => {
  if (TYPE_CONSTANT.ALL_INTEGRATIONS?.google_tag_manager?.gtm) {
    window.dataLayer?.push({
      event: event,
      ...data,
    });
  }
};
