/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { Listing_text } from '../../../constant/Translation/Listing';

//addNegotiate
export const addNegotiate = createAsyncThunk(
  'negotiate/addNegotiate',
  async ({ listing_id, params }, thunkAPI) => {
    try {
      const response = await axios.post('/api/negotiate/add', {
        id: listing_id,
        data: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      }

      // else {
      //   const { error } = await response.data;
      //   return error;
      // }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//updateNegotiate
export const updateNegotiate = createAsyncThunk(
  'negotiate/updateNegotiate',
  async ({ listing_id, updated_data, negotiation_id }, thunkAPI) => {
    try {
      const response = await axios.patch('/api/negotiate/update', {
        id: listing_id,
        data: updated_data,
        negotiation_id,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//buyerNegotiationsList
export const buyerNegotiationsList = createAsyncThunk(
  'negotiate/buyerNegotiationsList',
  async ({ listing_id, params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/negotiate/list', {
        params: {
          id: listing_id,
          params,
        },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//sellerNegotiationsList
export const sellerNegotiationsList = createAsyncThunk(
  'negotiate/sellerNegotiationsList',
  async ({ listing_id, params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/negotiate/list', {
        params: {
          id: listing_id,
          ...params,
        },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//sellerNegotiationsList
export const userBaseNegotiationsList = createAsyncThunk(
  'negotiate/userBaseNegotiationsList',
  async ({ listing_id, params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/negotiate/list', {
        params: {
          id: listing_id,
          ...params,
        },
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const negotiateSlice = createSlice({
  name: 'negotiate',
  initialState: {
    isFetching: false,
    isUserBaseListFetching: false,
    isSuccess: false,
    success_message: '',
    isError: false,
    errorMessage: '',
    buyer_negotiations_list: null,
    seller_negotiations_list: null,
    user_base_negotiations_list: null,
  },
  reducers: {
    clearNegotiateState: (state) => {
      state.buyer_negotiations_list = null;
      state.seller_negotiations_list = null;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
    clearUserBaseNegotiateState: (state) => {
      state.user_base_negotiations_list = null;
      state.isUserBaseListFetching = false;
      state.errorMessage = '';
      return state;
    },
  },
  extraReducers: {
    // addNegotiate
    [addNegotiate.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [addNegotiate.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.success_message = Listing_text.product?.negotiate_add_success;
        state.isShowSuccess = true;
      }
    },

    [addNegotiate.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    // updateNegotiate
    [updateNegotiate.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [updateNegotiate.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = true;
      state.success_message = Listing_text.product?.negotiate_add_success;
      state.isShowSuccess = true;
    },

    [updateNegotiate.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    // buyerNegotiationsList
    [buyerNegotiationsList.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [buyerNegotiationsList.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = true;
      state.buyer_negotiations_list = payload?.negotiations;
      state.isShowSuccess = true;
    },

    [buyerNegotiationsList.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    // sellerNegotiationsList
    [sellerNegotiationsList.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [sellerNegotiationsList.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.isFetching = false;
      state.isSuccess = true;
      state.seller_negotiations_list = payload?.negotiations;
      state.isShowSuccess = true;
    },

    [sellerNegotiationsList.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    // userBaseNegotiationsList
    [userBaseNegotiationsList.pending]: (state) => {
      state.isSuccess = false;
      state.isUserBaseListFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [userBaseNegotiationsList.fulfilled]: (state, { payload }) => {
      state.isError = false;
      state.isUserBaseListFetching = false;
      state.isSuccess = true;
      state.user_base_negotiations_list = payload?.negotiations;
      state.isShowSuccess = true;
    },

    [userBaseNegotiationsList.rejected]: (state, { payload }) => {
      state.isUserBaseListFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const { clearNegotiateState, clearUserBaseNegotiateState } =
  negotiateSlice.actions;
export const negotiateSelector = (state) => state.negotiate;
