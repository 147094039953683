//
export const getImageType = ({ url }) => {
  const type = url?.toLowerCase()?.split('.');
  return `.${type?.[type?.length - 1]}`;
};

//
export const checkImageType = ({
  current_type,
  selected_types = ['.png', '.jpg', '.jpeg', '.gif', '.webp'],
}) => {
  return selected_types?.includes(current_type);
};
