/* eslint-disable @typescript-eslint/no-var-requires */
import axios from 'axios';
import Cookies from 'js-cookie';
import { slug as SlugChecker } from 'github-slugger';
var CryptoJS = require('crypto-js');
const { cookie_name } = require('./url');

export function getAuthKey() {
  // let ckAuth = Cookies.get(`ck_p_a`)
  let ckAuth = ' ';
  if (ckAuth !== undefined) {
    const decrypted = CryptoJS.AES.decrypt(ckAuth, process.env.AES_SECRET);
    var aut_key = decrypted.toString(CryptoJS.enc.Utf8);
    return aut_key;
  } else {
    return undefined;
  }
}

export function Serialization(param) {
  var str = [];
  for (var p in param)
    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(param[p]));
  return str.join('&');
}

// Image Upload Function: pass images array , image object will like this {path:....,full_file:....}
export const Image_upload = async (
  all_images,
  setShowError,
  setError_message
) => {
  let all_images_uri = [];
  let upload_images = [];
  let upload_images_files = [];
  for (let i = 0; i < all_images.length; i++) {
    const element = all_images[i];

    // Set in state
    if (element.full_file === null) {
      all_images_uri.push(element.path);
    } else {
      upload_images.push({
        name: element.full_file.name.replace(/\s/g, '-'),
        type: element.full_file.type,
      });
      upload_images_files.push(element.full_file);
    }

    // Upload images checking
    if (all_images.length === i + 1 && upload_images.length > 0) {
      try {
        const response = await axios.post('/api/generateS3ImageURL', {
          data: {
            files: upload_images,
          },
        });

        const responseFiles = response.data.result;
        for (let index = 0; index < responseFiles.length; index++) {
          const path = responseFiles[index].signedUrl;
          const fileURI = responseFiles[index].fileUri;

          try {
            const res = await fetch(path, {
              method: 'PUT',
              headers: {
                ContentType: upload_images[index].type,
              },
              body: upload_images_files[index],
            });

            if (res.ok) {
              all_images_uri.push(fileURI);
            }
          } catch (error) {
            setShowError(true);
            setError_message(error?.response?.data?.message);
            false;
          }
        }
      } catch (error) {
        console.log('error', error);
        setShowError(true);
        setError_message(error?.response?.data?.message);
        false;
      }
    }

    // return all images url
    if (all_images_uri.length == all_images.length) {
      return all_images_uri;
    }
  }
};

// convert image size
export function formatBytes(bytes, decimals = 2) {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
}

// Encrypt data with AES

export function encryptDataWithAES(data) {
  return CryptoJS.AES.encrypt(data, process.env.TEMPLATE_SECRET_KEY).toString();
}

//Decreypt
export function decryptDataWithAES(data) {
  var bytes = CryptoJS.AES.decrypt(data, process.env.TEMPLATE_SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
}
