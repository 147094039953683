/*
headerStylesMaxWidth
headerStylesPaddingX
headerLogoAlignment

*/

export const header_styles_configs = ({ is_draft, all_configs }) => {
  let root = document.documentElement;

  const header_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.header ?? {}
    : all_configs?.styles?.header ?? {};
  root.style.setProperty(
    '--headerStylesMaxWidth',
    header_styles?.max_width ?? '1400px'
  );
  root.style.setProperty(
    '--headerStylesPaddingX',
    header_styles?.padding_x ?? '16px'
  );
  root.style.setProperty(
    '--headerLogoAlignment',
    header_styles?.logo_alignment ?? 'center'
  );
};
