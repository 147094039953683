/*
-------->Variable name<--------

accountCardBoxShadow;
accountCardBackgroundColor;
accountCardImageBorderRadius;
accountCardBorderRadius;
accountCardAccountNameColor;
accountCardOwnerNameColor;
accountCardAccountNameTextSize;
accountCardOwnerNameTextSize;
accountCardPerRow
accountCardHoverBoxShadow
accountCardHoverBackgroundColor

accountCardAccountImageHeight
accountCardAccountImageWidth
accountCardAccountNameTextSize
accountCardAccountNameColor
accountCardAccountNameTextFontWeight
accountCardAccountNameTextLineHeight
accountCardFollowButtonTextSize
accountCardFollowButtonColor
accountCardFollowButtonTextFontWeight
accountCardFollowButtonTextLineHeight
accountCardFlexDirection
accountCardFlexWrap
accountCardJustifyContent
accountCardAlignItems
accountCardAlignContents
accountCardRowGap
accountCardColumnGap

accountCardMarginRight
accountCardMarginLeft
accountCardMarginTop
accountCardMarginBottom
accountCardPaddingLeft
accountCardPaddingRight
accountCardPaddingTop
accountCardPaddingBottom

accountCardDirection

accountCardImageHeight
accountCardImageWidth

accountCardDirectionButtonBackground
accountCardDirectionButtonBackgroundImage
accountCardDirectionButtonHeight
accountCardDirectionButtonWidth
accountCardDirectionButtonMaxWidth
accountCardDirectionButtonMinHeight
accountCardDirectionButtonRounded
accountCardDirectionButtonShadow
accountCardDirectionButtonTextSize
accountCardDirectionButtonTextColor
accountCardDirectionButtonFontWeight
accountCardDirectionButtonLineHeight
accountCardDirectionMarginRight
accountCardDirectionMarginLeft
accountCardDirectionMarginTop
accountCardDirectionMarginBottom
accountCardDirectionPaddingLeft
accountCardDirectionPaddingRight
accountCardDirectionPaddingTop
accountCardDirectionPaddingBottom
--------> 👆👆👆 Variable name 👆👆👆 .++..<--------


*/

export const account_card_config_setup = ({ is_draft, all_configs }) => {
  let root = document.documentElement;

  const account_card_styles = is_draft
    ? all_configs?.draft_styles?.styles?.styles?.account_card ?? {}
    : all_configs?.styles?.account_card ?? {};

  //Card
  root.style.setProperty(
    '--accountCardBoxShadow',
    account_card_styles?.card?.box_shadow ?? '0px 1px 2px rgba(0, 0, 0, 0.24)'
  );

  root.style.setProperty(
    '--accountCardBackgroundColor',
    account_card_styles?.card?.background_color ?? '#FEFEFE'
  );

  root.style.setProperty(
    '--accountCardBorderRadius',
    account_card_styles?.card?.curve ?? '8px'
  );
  root.style.setProperty(
    '--accountCardPerRow',
    account_card_styles?.card?.max_items_per_row ?? '7'
  );
  root.style.setProperty(
    '--accountCardMinWidth',
    account_card_styles?.card?.min_width ?? '170px'
  );
  root.style.setProperty(
    '--accountCardMaxWidth',
    account_card_styles?.card?.max_width ?? 'auto'
  );

  root.style.setProperty(
    '--accountCardFlexDirection',
    account_card_styles?.card?.flex_direction ?? 'row'
  );
  root.style.setProperty(
    '--accountCardFlexWrap',
    account_card_styles?.card?.flex_wrap ?? 'no-wrap'
  );
  root.style.setProperty(
    '--accountCardJustifyContent',
    account_card_styles?.card?.justify_content ?? ''
  );
  root.style.setProperty(
    '--accountCardAlignItems',
    account_card_styles?.card?.align_items ?? ''
  );
  root.style.setProperty(
    '--accountCardAlignContents',
    account_card_styles?.card?.align_content ?? ''
  );
  root.style.setProperty(
    '--accountCardRowGap',
    account_card_styles?.card?.row_gap ?? '24px'
  );
  root.style.setProperty(
    '--accountCardColumnGap',
    account_card_styles?.card?.column_gap ?? '24px'
  );

  root.style.setProperty(
    '--accountCardMarginRight',
    account_card_styles?.card?.margin_right ?? ''
  );
  root.style.setProperty(
    '--accountCardMarginLeft',
    account_card_styles?.card?.margin_left ?? ''
  );
  root.style.setProperty(
    '--accountCardMarginTop',
    account_card_styles?.card?.margin_top ?? ''
  );
  root.style.setProperty(
    '--accountCardMarginBottom',
    account_card_styles?.card?.margin_bottom ?? ''
  );
  root.style.setProperty(
    '--accountCardPaddingLeft',
    account_card_styles?.card?.padding_left ?? '8px'
  );
  root.style.setProperty(
    '--accountCardPaddingRight',
    account_card_styles?.card?.padding_right ?? '8px'
  );
  root.style.setProperty(
    '--accountCardPaddingTop',
    account_card_styles?.card?.padding_top ?? '8px'
  );
  root.style.setProperty(
    '--accountCardPaddingBottom',
    account_card_styles?.card?.padding_bottom ?? '8px'
  );

  // Image

  root.style.setProperty(
    `--accountCardImageHeight`,
    account_card_styles?.image?.height
      ? account_card_styles?.image?.height
      : '100%'
  );
  root.style.setProperty(
    `--accountCardImageWidth`,
    account_card_styles?.image?.width
      ? account_card_styles?.image?.width
      : '100%'
  );
  root.style.setProperty(
    '--accountCardImageBorderRadius',
    account_card_styles?.image?.curve
      ? account_card_styles?.image?.curve
      : account_card_styles?.card?.image_curve ?? '0px'
  );

  // AccountName
  root.style.setProperty(
    '--accountCardAccountNameColor',
    account_card_styles?.account_name?.text_color ??
      all_configs?.onboarding?.app_color_primary ??
      '#004327'
  );

  root.style.setProperty(
    '--accountCardAccountNameTextSize',
    account_card_styles?.account_name?.text_size ?? '14px'
  );
  root.style.setProperty(
    '--accountCardAccountNameTextFontWeight',
    account_card_styles?.account_name?.font_weight ?? '600'
  );
  root.style.setProperty(
    '--accountCardAccountNameTextLineHeight',
    account_card_styles?.account_name?.line_height ?? '16px'
  );

  // Location
  root.style.setProperty(
    '--accountCardLocationColor',
    account_card_styles?.location?.text_color ?? ''
  );
  root.style.setProperty(
    '--accountCardLocationTextSize',
    account_card_styles?.location?.text_size ?? '14px'
  );
  root.style.setProperty(
    '--accountCardLocationTextFontWeight',
    account_card_styles?.location?.font_weight ?? '500'
  );
  root.style.setProperty(
    '--accountCardLocationTextLineHeight',
    account_card_styles?.location?.line_height ?? '14px'
  );

  // owner_name
  root.style.setProperty(
    '--accountCardOwnerNameColor',
    account_card_styles?.owner_name?.text_color ?? ''
  );
  root.style.setProperty(
    '--accountCardOwnerNameTextSize',
    account_card_styles?.owner_name?.text_size ?? '14px'
  );
  root.style.setProperty(
    '--accountCardOwnerNameTextFontWeight',
    account_card_styles?.owner_name?.font_weight ?? '500'
  );
  root.style.setProperty(
    '--accountCardOwnerNameTextLineHeight',
    account_card_styles?.owner_name?.line_height ?? '14px'
  );

  //follow button
  // root.style.setProperty(
  //   '--accountCardFollowButtonTextSize',
  //   account_card_styles?.follow_button?.follow_button_text_size ?? '10px'
  // );
  // root.style.setProperty(
  //   '--accountCardFollowButtonColor',
  //   account_card_styles?.follow_button?.follow_button_text_color ?? '#4f4f4f9a'
  // );
  // root.style.setProperty(
  //   '--accountCardFollowButtonTextFontWeight',
  //   account_card_styles?.follow_button?.follow_button_font_weight ?? '500'
  // );
  // root.style.setProperty(
  //   '--accountCardFollowButtonTextLineHeight',
  //   account_card_styles?.follow_button?.follow_button_line_height ?? '12px'
  // );

  // get direction button
  root.style.setProperty(
    '--accountCardDirectionButtonBackground',
    account_card_styles?.direction_button?.background ??
      all_configs?.onboarding?.app_color_primary ??
      '#004327'
  );
  root.style.setProperty(
    '--accountCardDirectionButtonBackgroundImage',
    account_card_styles?.direction_button?.background_image_url ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionButtonHeight',
    account_card_styles?.direction_button?.height ?? 'auto'
  );
  root.style.setProperty(
    '--accountCardDirectionButtonWidth',
    account_card_styles?.direction_button?.width ?? 'auto'
  );
  root.style.setProperty(
    '--accountCardDirectionButtonMaxWidth',
    account_card_styles?.direction_button?.max_width ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionButtonMinHeight',
    account_card_styles?.direction_button?.min_height ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionButtonRounded',
    account_card_styles?.direction_button?.border_radius ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionButtonShadow',
    account_card_styles?.direction_button?.box_shadow ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionButtonTextSize',
    account_card_styles?.direction_button?.text_size ?? '10px'
  );
  root.style.setProperty(
    '--accountCardDirectionButtonTextColor',
    account_card_styles?.direction_button?.text_color ?? '#ffffff'
  );
  root.style.setProperty(
    '--accountCardDirectionButtonFontWeight',
    account_card_styles?.direction_button?.font_weight ?? '400'
  );
  root.style.setProperty(
    '--accountCardDirectionButtonLineHeight',
    account_card_styles?.direction_button?.line_height ?? '12px'
  );

  root.style.setProperty(
    '--accountCardDirectionMarginRight',
    account_card_styles?.direction_button?.margin_right ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionMarginLeft',
    account_card_styles?.direction_button?.margin_left ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionMarginTop',
    account_card_styles?.direction_button?.margin_top ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionMarginBottom',
    account_card_styles?.direction_button?.margin_bottom ?? ''
  );
  root.style.setProperty(
    '--accountCardDirectionPaddingLeft',
    account_card_styles?.direction_button?.padding_left ?? '8px'
  );
  root.style.setProperty(
    '--accountCardDirectionPaddingRight',
    account_card_styles?.direction_button?.padding_right ?? '8px'
  );
  root.style.setProperty(
    '--accountCardDirectionPaddingTop',
    account_card_styles?.direction_button?.padding_top ?? '4px'
  );
  root.style.setProperty(
    '--accountCardDirectionPaddingBottom',
    account_card_styles?.direction_button?.padding_bottom ?? '4px'
  );

  // get direction button
  root.style.setProperty(
    '--accountCardCategoryButtonBackground',
    account_card_styles?.category_button?.background ??
      all_configs?.onboarding?.app_color_primary ??
      '#004327'
  );
  root.style.setProperty(
    '--accountCardCategoryButtonBackgroundImage',
    account_card_styles?.category_button?.background_image_url ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryButtonHeight',
    account_card_styles?.category_button?.height ?? 'auto'
  );
  root.style.setProperty(
    '--accountCardCategoryButtonWidth',
    account_card_styles?.category_button?.width ?? 'auto'
  );
  root.style.setProperty(
    '--accountCardCategoryButtonMaxWidth',
    account_card_styles?.category_button?.max_width ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryButtonMinHeight',
    account_card_styles?.category_button?.min_height ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryButtonRounded',
    account_card_styles?.category_button?.border_radius ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryButtonShadow',
    account_card_styles?.category_button?.box_shadow ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryButtonTextSize',
    account_card_styles?.category_button?.text_size ?? '10px'
  );
  root.style.setProperty(
    '--accountCardCategoryButtonTextColor',
    account_card_styles?.category_button?.text_color ?? '#ffffff'
  );
  root.style.setProperty(
    '--accountCardCategoryButtonFontWeight',
    account_card_styles?.category_button?.font_weight ?? '400'
  );
  root.style.setProperty(
    '--accountCardCategoryButtonLineHeight',
    account_card_styles?.category_button?.line_height ?? '12px'
  );

  root.style.setProperty(
    '--accountCardCategoryMarginRight',
    account_card_styles?.category_button?.margin_right ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryMarginLeft',
    account_card_styles?.category_button?.margin_left ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryMarginTop',
    account_card_styles?.category_button?.margin_top ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryMarginBottom',
    account_card_styles?.category_button?.margin_bottom ?? ''
  );
  root.style.setProperty(
    '--accountCardCategoryPaddingLeft',
    account_card_styles?.category_button?.padding_left ?? '8px'
  );
  root.style.setProperty(
    '--accountCardCategoryPaddingRight',
    account_card_styles?.category_button?.padding_right ?? '8px'
  );
  root.style.setProperty(
    '--accountCardCategoryPaddingTop',
    account_card_styles?.category_button?.padding_top ?? '4px'
  );
  root.style.setProperty(
    '--accountCardCategoryPaddingBottom',
    account_card_styles?.category_button?.padding_bottom ?? '4px'
  );

  // hover
  root.style.setProperty(
    '--accountCardHoverBoxShadow',
    account_card_styles?.hover?.box_shadow ?? '0px 1px 2px rgba(0, 0, 0, 0.24)'
  );

  root.style.setProperty(
    '--accountCardHoverBackgroundColor',
    account_card_styles?.hover?.background_color ??
      account_card_styles?.card?.background_color ??
      '#FEFEFE'
  );
  root.style.setProperty(
    '--accountCardImageZoomIN',
    account_card_styles?.hover?.image_zoom_in ?? false
  );
};
