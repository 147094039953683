/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const postTranslateContent = createAsyncThunk(
  'translate_content/postTranslateContent',
  async ({ ...bodyParam }, thunkAPI) => {
    try {
      const response = await axios.post('/api/translate/post', {
        translateData: bodyParam,
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const translateContentSlice = createSlice({
  name: 'translate_content',
  initialState: {
    isFetching: false,
    isError: false,
    errorMessage: '',
    isSuccess: '',
    translated_content: {},
  },
  reducers: {
    clearTranslateContentState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.translated_content = {};
      return state;
    },
  },
  extraReducers: {
    [postTranslateContent.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [postTranslateContent.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = '';
      state.translated_content = {};
    },

    [postTranslateContent.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload?.message;
      state.variant_digital_content = {};
    },
  },
});

export const { clearTranslateContentState } = translateContentSlice.actions;
export const translateContentSelector = (state) => state.translate_content;
