/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

export const getBlogs = createAsyncThunk(
  'layers/getBlogs',
  async ({ page }, thunkAPI) => {
    try {
      const response = await axios.get('/api/c/article', {
        params: { page, type: 'blog' },
      });

      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const layersSlice = createSlice({
  name: 'layers',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',

    blogs: null,
    page: 0,
    total_records: 0,
  },
  reducers: {
    clearErrorState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },

    setBlogsData: (state, { payload }) => {
      state.blogs = payload.layers;
      state.page = payload?.page ?? 1;
      state.total_records = payload?.total_records;
    },
  },
  extraReducers: {
    [getBlogs.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.blogs = payload?.layers;
        state.page = payload?.page ?? 1;
        state.total_records = payload?.total_records;
      }
    },
    [getBlogs.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getBlogs.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.blogs = null;
    },
  },
});

export const { setBlogsData } = layersSlice.actions;
export const layersSelector = (state) => state.layers;
