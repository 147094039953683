/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState: {
    sidebar_id: '',
    isSidebarOpen: false,
    modal_section_styles: '',
    modal_inside_section_styles: '',
    is_close_by_outer_click: true,
    sidebar_inside_component: null,
  },
  reducers: {
    onOpenSidebar: (state, { payload }) => {
      state.isSidebarOpen = true;
      state.modal_section_styles = payload?.modal_section_styles ?? '';
      state.modal_inside_section_styles =
        payload?.modal_inside_section_styles ?? '';
      state.is_close_by_outer_click = payload?.is_close_by_outer_click ?? true;
      state.sidebar_id = payload?.sidebar_id ?? '';
      state.sidebar_inside_component =
        payload?.sidebar_inside_component ?? null;
      return state;
    },
    onCloseSidebar: (state, { payload }) => {
      state.isSidebarOpen = false;
      // state.modal_section_styles = '';
      // state.modal_inside_section_styles = '';
      state.is_close_by_outer_click = true;
      state.sidebar_id = '';
      state.sidebar_inside_component = null;
      return state;
    },
  },
});

export const { onCloseSidebar, onOpenSidebar } = sidebarSlice.actions;
export const sidebarSelector = (state) => state.sidebar;
