/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';
import { Listing_text } from '../../../constant/Translation/Listing';

export const createTax = createAsyncThunk(
  'taxes/createTax',

  async ({ params }, thunkAPI) => {
    try {
      const response = await axios.post('/api/tax/add', {
        data: params,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const getListingTaxes = createAsyncThunk(
  'taxes/getListingTaxes',

  async ({ ...params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/tax', {
        params: params,
      });
      const { data } = await response;

      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const taxesSlice = createSlice({
  name: 'taxes',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    tax_list: null,
  },
  reducers: {
    clearErrorState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
  },
  extraReducers: {
    // addNegotiate
    [createTax.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [createTax.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        // state.success_message = Listing_text.product?.negotiate_add_success;
        state.isShowSuccess = true;
      }
    },

    [createTax.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //getWalletBalance
    [getListingTaxes.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.tax_list = payload?.taxes;
      }
    },

    [getListingTaxes.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getListingTaxes.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const { clearErrorState } = taxesSlice.actions;
export const taxSelector = (state) => state.tax;
