/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

export const getListingsCataloguePageSections = createAsyncThunk(
  'tagListingsPage/getTagListingsPageSections',

  async ({ sections_data }, thunkAPI) => {
    let promises = sections_data
      .filter((item) => !item.isStatic)
      .map((item) => {
        switch (item.block) {
          case 'all_listings':
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };

          default:
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
        }
      });

    try {
      let all_response = await Promise.all(
        promises.map((item) => item.fetch_call)
      );
      return all_response.map((response, index) => {
        delete promises[index].fetch_call;

        switch (promises[index].block) {
          case 'all_listings':
            return {
              ...promises[index],
              data: response.data,
            };

          default:
            return {
              ...promises[index],
              data:
                response.data[promises[index].block_data_key] ?? response.data,
            };
        }
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors.errors);
    }
  }
);

//Refecth section data
export const getListingsCataloguePageSection = createAsyncThunk(
  'tagListingsPage/getTagListingsPageSection',

  async ({ sections_data }, thunkAPI) => {
    let promises = sections_data
      .filter((item) => !item.isStatic || item.block === 'promo_banner')
      .map((item) => {
        switch (item.block) {
          case 'all_listings':
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };

          default:
            return {
              fetch_call: axios.get('/api/l', {
                params: { ...item.fetch_by },
              }),
              ...item,
            };
        }
      });

    try {
      let all_response = await Promise.all(
        promises.map((item) => item.fetch_call)
      );
      return all_response.map((response, index) => {
        // sending response
        switch (promises[index].block) {
          case 'all_listings':
            delete promises[index].fetch_call;
            return {
              ...promises[index],
              data: {
                listings:
                  promises[index].extra_data?.pagination_type === 'scroll'
                    ? [
                        ...promises[index].data.listings,
                        ...response.data?.listings,
                      ]
                    : response.data?.listings,
                page: response.data?.page,
                total_records: response.data?.total_records,
              },
            };

          default:
            delete promises[index].fetch_call;
            return {
              ...promises[index],
              data:
                response.data[promises[index].block_data_key] ?? response.data,
            };
        }
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errors.errors);
    }
  }
);

export const listingsCataloguePageSlice = createSlice({
  name: 'listingsCataloguePage',
  initialState: {
    isFetching: false,
    isSingleItemFetching: false,
    isSingleSectionFetching: '',
    isSuccess: false,
    isError: false,
    errors: null,
    errorMessage: '',
    listings_catalogue_page_sections: null,
  },
  reducers: {
    clearErrorState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errors = null;
      return state;
    },
    tagListingsPageSectionOptimisticUpdate: (state, { payload }) => {
      // Always remember this optimistic update not like RTK query , this is some advance functionalities created for best exprience , please look at the dispatch calling
      state.listings_catalogue_page_sections = payload;
    },
    setError: (state, { payload }) => {
      state.isError = true;
      state.errors = payload;
    },
  },
  extraReducers: {
    [getListingsCataloguePageSections.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
      errors: null;
    },
    [getListingsCataloguePageSections.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
      state.listings_catalogue_page_sections = payload;
    },

    [getListingsCataloguePageSections.rejected]: (
      state,
      { error, payload }
    ) => {
      state.isFetching = false;
      state.isError = true;
      state.errors = payload;
    },
    ///
    [getListingsCataloguePageSection.pending]: (state, { meta }) => {
      state.isSingleItemFetching = true;
      state.isSingleSectionFetching = meta?.arg?.sections_data[0]?.block;
      state.isError = false;
      state.errorMessage = '';
      errors: null;
    },
    [getListingsCataloguePageSection.fulfilled]: (state, { payload }) => {
      state.isSingleItemFetching = false;
      state.isSingleSectionFetching = '';

      state.isSuccess = true;
      state.isError = false;
      state.listings_catalogue_page_sections = [
        ...state.listings_catalogue_page_sections?.filter(
          (section) =>
            !payload.some(
              (nupdated_section) =>
                nupdated_section.order_number === section.order_number
            )
        ),
        ...payload,
      ];
    },

    [getListingsCataloguePageSection.rejected]: (state, { error, payload }) => {
      state.isSingleSectionFetching = '';

      state.isSingleItemFetching = false;
      state.isError = true;
      state.errors = payload;
    },
  },
});

export const {
  tagListingsPageSectionOptimisticUpdate,
  clearErrorState,
  setError,
} = listingsCataloguePageSlice.actions;

export const listingsCataloguePageSelector = (state) =>
  state.listingsCataloguePage;
