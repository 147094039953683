/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

export const get_transactions = createAsyncThunk(
  'transactions/get_transactions',
  async ({ bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/transactions', {
        params: bodyParam,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const transactionsSlice = createSlice({
  name: 'transactions',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    transactions_list: null,
    total_records: '',
    page: '',
  },
  reducers: {
    clearTransactionsList: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.transactions_list = null;
      state.page = '';
      state.total_records = '';
      return state;
    },
    setTransactionsList: (state, { payload }) => {
      state.transactions_list = payload?.transactions;
      state.page = payload?.page;
      state.total_records = payload?.page;
      return state;
    },
  },
  extraReducers: {
    [get_transactions.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.transactions_list = payload?.transactions;
        state.total_records = payload?.total_records;
        state.page = payload?.page;
      }
    },
    [get_transactions.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [get_transactions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const { clearTransactionsList, setTransactionsList } =
  transactionsSlice.actions;
export const transactionsSelector = (state) => state.transactions;
