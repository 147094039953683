/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

export const getCollections = createAsyncThunk(
  'collections/getCollections',
  async ({ bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/collections', {
        params: { ...bodyParam },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getCollectionData = createAsyncThunk(
  'collections/getCollectionData',
  async ({ bodyParam }, thunkAPI) => {
    try {
      const response = await axios.get('/api/collections', {
        params: { ...bodyParam },
      });

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const collectionsSlice = createSlice({
  name: 'collections',
  initialState: {
    isFetching: false,
    isError: false,
    errorMessage: '',
    isSuccess: '',
    collections: [],
    collection_data: null,
  },
  reducers: {
    clearCollectionsState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.collections = [];
      return state;
    },

    clearCollectionData: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.collection_data = null;
      return state;
    },

    setCollectionData: (state, { payload }) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      state.collection_data = payload;
      return state;
    },
  },
  extraReducers: {
    [getCollections.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getCollections.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = '';
      state.collections = payload?.collections;
    },
    [getCollections.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      state.collections = [];
    },
    //
    [getCollectionData.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getCollectionData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = false;
      state.errorMessage = '';
      state.collection_data =
        payload?.collections?.length > 0 ? payload?.collections[0] : {};
    },
    [getCollectionData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
      state.collections = null;
    },
  },
});

export const { clearCollectionsState, setCollectionData, clearCollectionData } =
  collectionsSlice.actions;
export const collectionsSelector = (state) => state.collections;
