/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

//
export const getMerchantDetails = createAsyncThunk(
  'wallet/getMerchantDetails',

  async ({ ...prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/opp/merchant_details', {
        params: prams,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const OPPSlice = createSlice({
  name: 'opp',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    merchant_details: null,
  },
  reducers: {
    clearMerchantDetails: (state) => {
      state.merchant_details = null;
      return state;
    },
  },

  extraReducers: {
    //getWalletBalance
    [getMerchantDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.merchant_details = payload;
      }
    },

    [getMerchantDetails.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getMerchantDetails.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const { clearMerchantDetails } = OPPSlice.actions;
export const OPPSelector = (state) => state.opp;
