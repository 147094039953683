/* eslint-disable react/prop-types */
import '../styles/globals.scss';
import 'cropperjs/dist/cropper.css';
import Link from 'next/link';

import { Provider } from 'react-redux';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { TYPE_CONSTANT } from '../constant/Web_constant';
import { useRouter } from 'next/router';
import Loading from '../components/Shared/Loading/Loading';
import Cookies from 'js-cookie';

import NoSSRIntegrations from '../integrations/NoSSRIntegrations';
import { set_root_configs } from '../constant/Root_configs_set';
import { getCookie, setCookie } from 'cookies-next';
import { push_data_layer } from '../constant/GTMDatalayer';

import { decryptDataWithAES, encryptDataWithAES } from '../constant/functions';
import DomainNotFound from '../components/Domain/DomainNotFound';
import SEO_Head from '../constant/SEO_Head';
import { initial_metadata_set } from '../utils/InitialMetadaSet';
import { uuid } from 'uuidv4';
import SubApp from '../components/Root/SubApp';

import store from '../store/store';

function MyApp({ Component, pageProps, err }) {
  const router = useRouter();

  const is_draft = router?.query?.draft ? true : false;

  TYPE_CONSTANT.IS_DRAFT = router?.query?.draft ? true : false;

  //
  const [is_connected, setIs_connected] = useState(false);
  const [is_connected_error, setIs_connectedError] = useState(false);

  const [loading, setLoading] = useState(false);

  TYPE_CONSTANT.DOMAIN =
    typeof window !== 'undefined' && window.location.host
      ? window.location.host
      : '';

  // set in cookies for is_draft
  useEffect(() => {
    let inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000);
    is_draft &&
      Cookies.set(`is_draft`, true, {
        expires: inOneHour,
      });
  }, [is_draft]);

  //loading for changing url
  useEffect(() => {
    const handleStart = (url) => {
      url !== router.pathname ? setLoading(true) : setLoading(false);
    };
    const handleComplete = (url) => setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);
  }, [router]);

  //GTM page view
  useEffect(() => {
    push_data_layer({
      event: 'Page View',
      data: {
        path_name: router.asPath,
      },
    });
  }, [router.asPath]);

  useEffect(() => {
    initial_metadata_set();
    // uuid set
    if (!getCookie(`${window?.location?.host}_default_uuid`)) {
      const uUid = uuid();
      setCookie(
        `${window?.location?.host}_default_uuid`,
        encryptDataWithAES(uUid)
      );
      TYPE_CONSTANT.UUID = `${window?.location?.host}_default_uuid`;
    } else {
      TYPE_CONSTANT.UUID = decryptDataWithAES(
        getCookie(`${window?.location?.host}_default_uuid`)
      );
    }

    //
    axios
      .get('/api')
      .then((res) => {
        if (typeof window !== 'undefined') {
          const response_configs = JSON.parse(
            decryptDataWithAES(res?.data?.configs)
          );

          // is window should reload
          if (res?.data?.window_should_reload) {
            window.location.reload();
          }

          //
          if (Object.keys(response_configs)?.length > 0) {
            set_root_configs({
              is_draft: router?.query?.draft,
              all_configs: response_configs,
            });
            TYPE_CONSTANT.AVAILABLE_LANGUAGES = res?.data?.tenant_languages;
            TYPE_CONSTANT.CURRENCIES = res?.data?.currencies;
            TYPE_CONSTANT.SELECTED_CURRENCY = res?.data?.selected_currency;
            TYPE_CONSTANT.CURRENCY = res?.data?.selected_currency;
            TYPE_CONSTANT.DIR = getCookie(`${TYPE_CONSTANT.DOMAIN}_direction`);

            localStorage.setItem(
              'available_languages',
              JSON.stringify(res?.data?.tenant_languages)
            );
            localStorage.setItem(
              'available_currencies',
              JSON.stringify(res?.data?.currencies)
            );

            setIs_connected(true);
          } else {
            router.push('/domain-not-found');
            setIs_connectedError(true);
          }
        }
      })
      .catch((error) => {
        console.log('ERROR>>>>>>>', error);
        router.push('/domain-not-found');
        setIs_connectedError(true);
      });
  }, [0]);

  return (
    <>
      {is_connected && (
        <>
          <Provider store={store}>
            <Loading loading={loading} />
            <SubApp />
            <Component {...pageProps} err={err} />
            {!router.pathname.includes('/embed') && (
              <NoSSRIntegrations
                all_integrations={TYPE_CONSTANT.ALL_INTEGRATIONS}
              />
            )}
            {/* tradly  footer note*/}
            {!TYPE_CONSTANT.GENERAL_CONFIGS?.hide_tradly_footer_note &&
              !router.pathname.includes('/embed') && (
                <Link
                  href={`https://tradly.app?utm_source=plg&utm_medium=built_button&utm_campaign=${window?.location?.host}`}
                >
                  <a
                    className=" fixed bottom-5 left-5 z-50 shadow px-2 py-2 flex items-center gap-2 rounded-button bg-black cursor-pointer"
                    target="_blank"
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 126 126"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M63 0C97.7939 0 126 28.2061 126 63C126 97.7939 97.7939 126 63 126C28.2061 126 0 97.7939 0 63C0 28.2061 28.2061 0 63 0Z"
                        fill="url(#paint0_linear)"
                      ></path>
                      <path
                        opacity="0.5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M63 119C93.9279 119 119 93.9279 119 63C119 32.0721 93.9279 7 63 7C32.0721 7 7 32.0721 7 63C7 93.9279 32.0721 119 63 119Z"
                        stroke="white"
                        strokeWidth="1.4"
                      ></path>
                      <path
                        opacity="0.5"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M63 105C86.196 105 105 86.196 105 63C105 39.804 86.196 21 63 21C39.804 21 21 39.804 21 63C21 86.196 39.804 105 63 105Z"
                        stroke="white"
                        strokeWidth="1.4"
                      ></path>
                      <path
                        d="M108.282 44.2442C105.799 38.2551 102.162 32.8652 97.6482 28.3518C88.7809 19.4845 76.5309 14 63 14C49.469 14 37.219 19.4845 28.3517 28.3518C23.8383 32.8652 20.2012 38.2551 17.7178 44.2442"
                        stroke="white"
                        strokeWidth="15.4"
                        strokeLinecap="round"
                      ></path>
                      <path
                        d="M63.0001 14.0001V111.222"
                        stroke="white"
                        strokeWidth="15.4"
                        strokeLinecap="round"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear"
                          x1="126"
                          y1="0"
                          x2="126"
                          y2="126"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="#2BDBC0"></stop>
                          <stop offset="1" stopColor="#13B58C"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                    <p className="text-sm font-semibold text-white  font-Inter-var">
                      Built with{' '}
                      <span className=" text-[#55d4a3] ">Tradly</span>
                    </p>
                  </a>
                </Link>
              )}
          </Provider>
        </>
      )}
      {is_connected_error && (
        <>
          <DomainNotFound />
        </>
      )}
    </>
  );
}

export default MyApp;
