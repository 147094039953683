export var TYPE_CONSTANT = {
  DOMAIN: '',
  UUID: '',
  CONFIGS_GROUPS: `general,onboarding,accounts,listings,integrations,payments,draft_styles,seo,styles,social,address,orders,shipping,pages`,
  IS_DRAFT: false,
  DIR: '',
  CURRENCY: '',
  AUTH_TYPE: 0,
  MARKETPLACE_MODULES: '',
  MARKETPLACE_FLAVOURS: '',
  AUTH_KEY: '',
  THEME: '',
  AUTH_KEY: '',
  META_TITLE: '',
  META_DESCRIPTIONS: '',
  META_ACCOUNT_TITLE: '',
  META_ACCOUNT_DESCRIPTIONS: '',
  META_LISTING_TITLE: '',
  META_LISTING_DESCRIPTION: '',
  META_LISTING_CATEGORY_TITLE: '',
  META_LISTING_CATEGORY_DESCRIPTION: '',

  GENERAL_CONFIGS: '',
  ONBOARDING_CONFIGS: '',
  ACCOUNTS_CONFIGS: '',
  LISTINGS_CONFIGS: '',
  ALL_INTEGRATIONS: '',
  PAYMENT_CONFIGS: '',
  DRAFT_STYLES: '',
  SEO_CONFIGS: '',
  STYLES_CONFIGS: '',
  SOCIAL_CONFIGS: '',
  ADDRESS_CONFIGS: '',
  ORDERS_CONFIGS: '',
  SHIPPING_CONFIGS: '',
  PAGES: '',

  AVAILABLE_LANGUAGES: [],
  ADD_LISTINGS_TYPES: [],
  SELECTED_LANGUAGE: '',
  CURRENCIES: [],

  PRIMARY_MENUS: [],
  SELECTED_CURRENCY: {},

  DEFAULT_IMAGE:
    'https://media.tradly.app/images/marketplace/11814/default-product-9kRrKqKq.jpg',
};
