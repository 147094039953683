// Listing card
export const listing_card_default_style = (theme) => {
  switch (theme) {
    case 1:
      return { height: '210px', width: '190px', isEditable: true };
      break;
    case 3:
      return { height: '100px', width: '100px', isEditable: true };
      break;
    case 4:
      return { height: null, width: null, isEditable: false };
      break;
    case 5:
      return { height: null, width: null, isEditable: false };
      break;
    case 6:
      return { height: null, width: null, isEditable: false };
      break;
    case 7:
      return { height: null, width: null, isEditable: false };
      break;
    case 8:
      return { height: null, width: null, isEditable: false };
      break;
    case 9:
      return { height: null, width: null, isEditable: false };
      break;
    case 12:
      return { height: null, width: null, isEditable: false };
      break;
    case 13:
      return { height: null, width: null, isEditable: false };
      break;
    case 14:
      return { height: null, width: null, isEditable: false };
      break;
    case 15:
      return { height: null, width: null, isEditable: false };
      break;

    default:
      return { height: '210px', width: '190px', isEditable: true };
      break;
  }
};
