/* eslint-disable no-param-reassign */
import { createSlice as cardSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

//
export const getSubscriptionList = createAsyncThunk(
  'card/getSubscriptionList',

  async ({ ...params }, thunkAPI) => {
    try {
      const response = await axios.get('/api/subscription/list', {
        params: params,
      });
      const { data } = await response;

      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const getSubscriptionDetails = createAsyncThunk(
  'card/getSubscriptionDetails',

  async ({ subscription_id, ...params }, thunkAPI) => {
    try {
      const response = await axios.get(`/api/subscription/${subscription_id}`, {
        params: params,
      });
      const { data } = await response;

      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const subscribeBYMangoPay = createAsyncThunk(
  'wallet/subscribeBYMangoPay',

  async ({ ...post_data }, thunkAPI) => {
    try {
      const response = await axios.post(
        '/api/subscription/subscribe_by_mangopay',
        post_data
      );
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const SubscriptionSlice = cardSlice({
  name: 'subscription',
  initialState: {
    isFetching: false,
    isSubscribeFetching: false,
    isDetailsLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    subscription_products: null,
    subscription_details: null,
    subscribe_resp: null,
  },
  reducers: {
    clearSubscriptionList: (state) => {
      state.subscription_products = null;
      return state;
    },
    clearSubscriptionDetails: (state) => {
      state.subscription_details = null;
      return state;
    },
  },
  extraReducers: {
    //getWalletBalance
    [getSubscriptionList.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.subscription_products = payload?.subscription_products;
      }
    },

    [getSubscriptionList.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getSubscriptionList.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //getSubscriptionDetails
    [getSubscriptionDetails.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isDetailsLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isDetailsLoading = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.subscription_details = payload?.subscription_product;
      }
    },

    [getSubscriptionDetails.pending]: (state) => {
      state.isDetailsLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getSubscriptionDetails.rejected]: (state, { payload }) => {
      state.isDetailsLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //subscribeBYMangoPay
    [subscribeBYMangoPay.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isSubscribeFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isSubscribeFetching = false;
        state.isSuccess = true;
        state.subscribe_resp = payload;
        state.errorMessage = '';
      }
    },

    [subscribeBYMangoPay.pending]: (state) => {
      state.isSubscribeFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [subscribeBYMangoPay.rejected]: (state, { payload }) => {
      state.isSubscribeFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const { clearSubscriptionList, clearSubscriptionDetails } =
  SubscriptionSlice.actions;
export const SubscriptionSelector = (state) => state.subscription;
