/* eslint-disable no-shadow */
/* eslint-disable camelcase */
/* eslint-disable no-param-reassign */
/* eslint-disable no-else-return */

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

export const getNotifications = createAsyncThunk(
  'notifications/getNotifications',
  async ({ page }, thunkAPI) => {
    try {
      const response = await axios.get('/api/activities', { params: { page } });

      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getMoreNotifications = createAsyncThunk(
  'notifications/getMoreNotifications',
  async ({ page }, thunkAPI) => {
    try {
      const response = await axios.get('/api/activities', { params: { page } });

      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',

    notifications: null,
    page: 0,
    total_records: 0,
  },
  reducers: {
    clearErrorState: (state) => {
      state.isError = false;
      state.isSuccess = false;
      state.isFetching = false;
      state.errorMessage = '';
      return state;
    },
  },
  extraReducers: {
    [getNotifications.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.notifications = payload?.activities;
        state.page = payload?.page;
        state.total_records = payload?.total_records;
      }
    },
    [getNotifications.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getNotifications.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
      state.notifications = null;
    },
    [getMoreNotifications.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.notifications = [...state.notifications, ...payload?.activities];
        state.page = payload?.page;
        state.total_records = payload?.total_records;
      }
    },
    [getNotifications.pending]: (state) => {
      state.isSuccess = false;
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getMoreNotifications.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
    },
  },
});

export const { clearErrorState } = notificationsSlice.actions;
export const notificationsSelector = (state) => state.notifications;
