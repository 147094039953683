import { changeDateFormat } from '../components/Shared/Constant/Constant';

export var metadata = {
  title: '',
  meta_description: '',
  url: '',
  image: '',
  favicon: '',
  primary_font_name: '',
  title_font_name: '',
  body_font_name: '',
};

export var structuredData = { json_data: {} };

export var all_integrations_data = {
  google_tag_manager: undefined,
  facebook_pixel: undefined,
};

export const getProductStructureData = (product_details, product_url) => {
  let product_structure = {
    '@context': 'https://schema.org/',
    '@type': 'Product',

    image: product_details.images,
    name: product_details.title,
    description: product_details?.description,
    brand: {
      '@type': 'Brand',
      name: product_details?.account?.name,
    },
    offers: {
      '@type': 'Offer',
      availability: product_details?.sold
        ? 'https://schema.org/SoldOut'
        : 'https://schema.org/InStock',
      url: product_url,
      price: product_details?.offer_price?.amount,
      priceCurrency: product_details?.offer_price?.currency,
    },
    // review:
    //   rating_data.length > 0
    //     ? {
    //         '@type': 'Review',
    //         reviewRating: {
    //           '@type': 'Rating',
    //           ratingValue:rat,
    //           bestRating: '5',
    //         },
    //         author: {
    //           '@type': 'Person',
    //           name: 'Fred Benson',
    //         },
    //       }
    //     : {},
  };

  if (product_details?.sku) {
    product_structure['sku'] = product_details?.sku;
  }
  if (
    product_details?.rating_data?.review_count > 0 &&
    product_details?.rating_data?.rating_average > 0
  ) {
    product_structure['aggregateRating'] = {
      '@type': 'AggregateRating',
      reviewCount: product_details?.rating_data?.review_count,
      ratingCount: product_details?.rating_data?.rating_count,
      ratingValue: product_details?.rating_data?.rating_average,
      bestRating: product_details?.rating_data?.rating_count_data?.rating_5,
      worstRating: product_details?.rating_data?.rating_count_data?.rating_1,
    };
  }
  return product_structure;
};

export const getEventStructureData = async (product_details, product_url) => {
  return {
    '@context': 'https://schema.org',
    '@type': 'Event',
    name: product_details?.title,
    startDate: changeDateFormat(product_details?.start_at, 'YYYY-dd-mm'),
    endDate: changeDateFormat(product_details?.end_at, 'YYYY-dd-mm'),

    // location: {
    //   '@type': 'Place',
    //   name: 'Snickerpark Stadium',
    //   address: {
    //     '@type': 'PostalAddress',
    //     streetAddress: '100 West Snickerpark Dr',
    //     addressLocality: 'Snickertown',
    //     postalCode: '19019',
    //     addressRegion: 'PA',
    //     addressCountry: 'US',
    //   },
    // },
    image: product_details.images,
    description: product_details?.description,
    offers: {
      '@type': 'Offer',
      url: product_url,
      price: product_details?.offer_price?.amount,
      priceCurrency: product_details?.offer_price?.currency,
    },
    organizer: {
      '@type': 'Organization',
      name: product_details?.account?.name,
      url: product_url,
    },
  };
};
