/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import tradly from 'tradly';

//
export const getWallets = createAsyncThunk(
  'wallet/getWallets',

  async ({}, thunkAPI) => {
    try {
      const response = await axios.get('/api/wallet');
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const getWalletBalance = createAsyncThunk(
  'wallet/getWalletBalance',

  async ({}, thunkAPI) => {
    try {
      const response = await axios.get('/api/wallet/balance');
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const getMangoPayWalletBalance = createAsyncThunk(
  'wallet/getMangoPayWalletBalance',

  async ({ ...prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/wallet/mangopay_balance', {
        params: prams,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const getWalletTransitions = createAsyncThunk(
  'wallet/getWalletTransitions',

  async ({ ...prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/wallet/transitions', {
        params: prams,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const getMangopayWalletTransactions = createAsyncThunk(
  'wallet/getMangopayWalletTransactions',

  async ({ ...prams }, thunkAPI) => {
    try {
      const response = await axios.get('/api/wallet/mangopay_transactions', {
        params: prams,
      });
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const amountPayInByMangoPayCalc = createAsyncThunk(
  'wallet/amountPayInByMangoPayCalc',

  async ({ ...post_data }, thunkAPI) => {
    try {
      const response = await axios.post('/api/wallet/pay_in_calc', post_data);
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const amountPayInByMangoPay = createAsyncThunk(
  'wallet/amountPayInByMangoPay',

  async ({ ...post_data }, thunkAPI) => {
    try {
      const response = await axios.post('/api/wallet/pay_in', post_data);
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const amountPayInByMangoPayBankWire = createAsyncThunk(
  'wallet/amountPayInByMangoPayBankWire',

  async ({ ...post_data }, thunkAPI) => {
    try {
      const response = await axios.post(
        '/api/wallet/bankwire_pay_in',
        post_data
      );
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const amountPayInByPaypal = createAsyncThunk(
  'wallet/amountPayInByPaypal',

  async ({ ...post_data }, thunkAPI) => {
    try {
      const response = await axios.post('/api/wallet/paypal_pay_in', post_data);
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const amountWithdrawByMangoPayCalc = createAsyncThunk(
  'wallet/amountWithdrawByMangoPayCalc',

  async ({ ...post_data }, thunkAPI) => {
    try {
      const response = await axios.post('/api/wallet/withdraw_calc', post_data);
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

//
export const amountWithdrawByMangoPayINIT = createAsyncThunk(
  'wallet/amountWithdrawByMangoPayINIT',

  async ({ ...post_data }, thunkAPI) => {
    try {
      const response = await axios.post('/api/wallet/withdraw_init', post_data);
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
//
export const amountWithdrawByMangoPayConfirm = createAsyncThunk(
  'wallet/amountWithdrawByMangoPayConfirm',

  async ({ ...post_data }, thunkAPI) => {
    try {
      const response = await axios.post(
        '/api/wallet/withdraw_confirm',
        post_data
      );
      const { data } = await response;
      if (!response.data.error) {
        return data;
      } else {
        const { error } = await response.data;
        return error;
      }
      return error;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const walletSlice = createSlice({
  name: 'wallet',
  initialState: {
    isFetching: false,
    isWalletsFetching: false,
    isPayINLoaidng: false,
    isPayINSuccess: false,
    isWithdrawCalcLoading: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
    pay_in_redirect_url: null,
    balance: null,
    mangopay_balance: null,
    pay_in_calculation: null,
    pay_in_bank_wire: null,
    pay_in_paypal: null,
    transitions_list: null,
    mangopay_transactions_list: null,
    transitions_total_records: null,
    transitions_current_page: 1,
    withdraw_calculation: null,
    withdraw_init: null,
    withdrawInitLoading: false,
    withdrawInitSuccess: false,
    withdrawConfirmSuccess: false,
    withdrawConfirmLoading: false,
    wallets_list: null,
  },
  reducers: {
    clearBalance: (state) => {
      state.balance = 0;
      return state;
    },
    clearWalletError: (state) => {
      state.isError = false;
      state.errorMessage = '';
      return state;
    },
    clearPayIN: (state) => {
      state.isPayINSuccess = false;
      state.pay_in_calculation = null;
      return state;
    },
    clearPayINBankWire: (state) => {
      state.isPayINSuccess = false;
      state.pay_in_bank_wire = null;
      return state;
    },
    clearPayINPaypal: (state) => {
      state.isPayINSuccess = false;
      state.pay_in_paypal = null;
      return state;
    },
    clearWithdraw: (state) => {
      state.withdraw_calculation = null;
      state.withdraw_init = null;
      state.withdrawInitLoading = false;
      state.withdrawInitSuccess = false;
      state.withdrawConfirmLoading = false;
      state.withdrawConfirmSuccess = false;
      return state;
    },
  },

  extraReducers: {
    //getWallets
    [getWallets.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isWalletsFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isWalletsFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.wallets_list = payload?.wallets;
      }
    },

    [getWallets.pending]: (state) => {
      state.isWalletsFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getWallets.rejected]: (state, { payload }) => {
      state.isWalletsFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //getWalletBalance
    [getWalletBalance.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.balance = payload?.balance;
      }
    },

    [getWalletBalance.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getWalletBalance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //getWalletBalance
    [getMangoPayWalletBalance.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.mangopay_balance = payload?.balance;
      }
    },

    [getMangoPayWalletBalance.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getMangoPayWalletBalance.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //getWalletTransitions
    [getWalletTransitions.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.transitions_list = payload?.transactions;
        state.transitions_total_records = payload?.total_records;
        state.transitions_current_page = payload?.page;
      }
    },

    [getWalletTransitions.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getWalletTransitions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //getWalletTransitions
    [getMangopayWalletTransactions.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.errorMessage = '';
        state.mangopay_transactions_list = payload?.transactions;
      }
    },

    [getMangopayWalletTransactions.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [getMangopayWalletTransactions.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //amountPayInByMangoPayCalc
    [amountPayInByMangoPayCalc.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isFetching = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isFetching = false;
        state.isSuccess = true;
        state.pay_in_calculation = payload;
        state.errorMessage = '';
      }
    },

    [amountPayInByMangoPayCalc.pending]: (state) => {
      state.isFetching = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [amountPayInByMangoPayCalc.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },
    //amountPayInByMangoPay
    [amountPayInByMangoPay.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isPayINLoaidng = false;
        state.isError = true;
        state.isPayINSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isPayINLoaidng = false;
        state.isPayINSuccess = payload?.success;
        state.pay_in_redirect_url = payload?.redirect_url;
        state.errorMessage = '';
      }
    },

    [amountPayInByMangoPay.pending]: (state) => {
      state.isPayINLoaidng = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [amountPayInByMangoPay.rejected]: (state, { payload }) => {
      state.isPayINLoaidng = false;
      state.isError = true;
      state.isPayINSuccess = false;
      state.errorMessage = payload?.message;
    },

    //amountPayInByMangoPay Bank Wire
    [amountPayInByMangoPayBankWire.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isPayINLoaidng = false;
        state.isError = true;
        state.isPayINSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isPayINLoaidng = false;
        state.isPayINSuccess = true;
        state.pay_in_bank_wire = payload;
        state.errorMessage = '';
      }
    },

    [amountPayInByMangoPayBankWire.pending]: (state) => {
      state.isPayINLoaidng = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [amountPayInByMangoPayBankWire.rejected]: (state, { payload }) => {
      state.isPayINLoaidng = false;
      state.isError = true;
      state.isPayINSuccess = false;
      state.errorMessage = payload?.message;
    },

    //amountPayInByPaypal paypal Wire
    [amountPayInByPaypal.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isPayINLoaidng = false;
        state.isError = true;
        state.isPayINSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isPayINLoaidng = false;
        state.isPayINSuccess = true;
        state.pay_in_paypal = payload;
        state.pay_in_redirect_url = payload?.redirect_url;
        state.errorMessage = '';
      }
    },

    [amountPayInByPaypal.pending]: (state) => {
      state.isPayINLoaidng = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [amountPayInByPaypal.rejected]: (state, { payload }) => {
      state.isPayINLoaidng = false;
      state.isError = true;
      state.isPayINSuccess = false;
      state.errorMessage = payload?.message;
    },

    //amountPayInByMangoPayCalc
    [amountWithdrawByMangoPayCalc.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.isWithdrawCalcLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.isWithdrawCalcLoading = false;
        state.isSuccess = true;
        state.withdraw_calculation = payload;
        state.errorMessage = '';
      }
    },

    [amountWithdrawByMangoPayCalc.pending]: (state) => {
      state.isWithdrawCalcLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [amountWithdrawByMangoPayCalc.rejected]: (state, { payload }) => {
      state.isWithdrawCalcLoading = false;
      state.isError = true;
      state.isSuccess = false;
      state.errorMessage = payload?.message;
    },

    //amountWithdrawByMangoPayINIT
    [amountWithdrawByMangoPayINIT.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.withdrawInitLoading = false;
        state.isError = true;
        state.withdrawInitSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.withdrawInitLoading = false;
        state.withdrawInitSuccess = true;
        state.withdraw_init = payload;
        state.errorMessage = '';
      }
    },

    [amountWithdrawByMangoPayINIT.pending]: (state) => {
      state.withdrawInitLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [amountWithdrawByMangoPayINIT.rejected]: (state, { payload }) => {
      state.withdrawInitLoading = false;
      state.isError = true;
      state.withdrawInitSuccess = false;
      state.errorMessage = payload?.message;
    },

    //amountWithdrawByMangoPayConfirm
    [amountWithdrawByMangoPayConfirm.fulfilled]: (state, { payload }) => {
      if (payload.code) {
        state.withdrawConfirmLoading = false;
        state.isError = true;
        state.withdrawConfirmSuccess = false;
        state.errorMessage = payload?.message;
      } else {
        state.isError = false;
        state.withdrawConfirmLoading = false;
        state.withdrawConfirmSuccess = payload?.success;
        state.errorMessage = '';
      }
    },

    [amountWithdrawByMangoPayConfirm.pending]: (state) => {
      state.withdrawConfirmLoading = true;
      state.isError = false;
      state.errorMessage = '';
    },
    [amountWithdrawByMangoPayConfirm.rejected]: (state, { payload }) => {
      state.withdrawConfirmLoading = false;
      state.isError = true;
      state.withdrawConfirmSuccess = false;
      state.errorMessage = payload?.message;
    },
  },
});

export const {
  clearBalance,
  clearPayIN,
  clearWithdraw,
  clearWalletError,
  clearPayINBankWire,
  clearPayINPaypal,
} = walletSlice.actions;
export const WalletSelector = (state) => state.wallet;
