import Head from 'next/head';
import React from 'react';
import Integrations from '../integrations/integrations';
import { metadata, structuredData } from './Metadata';
import { TYPE_CONSTANT } from './Web_constant';

const SEO_Head = () => {
  return (
    <Head>
      <title>{metadata.title}</title>
      <meta name="description" content={`${metadata?.meta_description}`} />
      <meta property="og:title" content={`${metadata?.title}`} key="title" />
      <meta key="og:type" property="og:type" content="website" />
      <meta property="og:url" content={`${metadata?.url}`} />
      <meta property="og:image" content={`${metadata?.image}`} />
      <meta
        property="og:description"
        content={`${metadata?.meta_description}`}
      />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />
      {/* <!-- Twitter --> */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={`${metadata?.url}`} />
      <meta property="twitter:title" content={`${metadata?.title}`} />
      <meta
        property="twitter:description"
        content={`${metadata?.meta_description}`}
      />
      <meta property="twitter:image" content={`${metadata?.image}`} />
      {/* Search console */}
      {TYPE_CONSTANT?.ALL_INTEGRATIONS?.google_search_console && (
        <meta
          name="google-site-verification"
          content={
            TYPE_CONSTANT?.ALL_INTEGRATIONS?.google_search_console?.content
          }
        />
      )}
      {/* Structured data */}
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(structuredData.json_data),
        }}
      />
      {/* favicon */}
      <link rel="icon" href={metadata?.favicon} />
      {/* font */}
      <link
        href={`https://fonts.googleapis.com/css2?family=${metadata?.primary_font_name}`}
        rel="stylesheet"
      />
      <link
        href={`https://fonts.googleapis.com/css2?family=${metadata?.title_font_name}`}
        rel="stylesheet"
      />
      <link
        href={`https://fonts.googleapis.com/css2?family=${metadata?.body_font_name}`}
        rel="stylesheet"
      />
      {/* Integrations */}
      {TYPE_CONSTANT?.ALL_INTEGRATIONS !== '' && (
        <Integrations all_integrations={TYPE_CONSTANT?.ALL_INTEGRATIONS} />
      )}
    </Head>
  );
};

export default SEO_Head;
